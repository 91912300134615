window.clientId = "b7814144-6e0a-446a-aa3c-8ec73f9a9641";
window.tenant = "prekpmgnetfr.onmicrosoft.com"
window.authority = "https://login.microsoftonline.com/" + window.tenant;
window.redirectUri = window.location.origin + "/admin"

window.SiteKey = "6Lct8RgiAAAAAKNYJwdS-YiAovLwePFvMsVMkwl-";
window.reCaptChaScore = 6;

window.DematDaysLimit = 5
window.DematExpirationHours = 24;

window.baseUrl = "https://api.pprod.ext.net.kpmg.fr";
window.BlobUrl = "https://kpmgfrsapprdematcoll001.blob.core.windows.net";
window.ApiUrl = window.baseUrl + "/dematcollect/";